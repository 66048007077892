<template>
  <div class="tournament-menu-wrapper">
    <div class="tournament-menu-animation"></div>
    <div
      v-show="!isCompact"
      class="tournament-menu-item"
      data-t="tournament-menu-item"
    >
      <div class="tournament-info">
        <div class="title-wrapper">
          <div class="subtitle">{{ t('tournament.tournament') }}</div>
          <div class="title">{{ card.name }}</div>
        </div>
        <PrizeAmount
          :amount="compactFormat(card.totalPrizePoolAmount)"
          :currency-icon="currencyIcon"
          :is-rise-up="isRiseUp"
          size="s"
        />
      </div>
      <div class="tournament-badges">
        <StBadge type="timer" :label="countdown" size="s" />
        <StBadge
          type="pedestal-solid"
          :label="card.prizePlaceNumber"
          size="s"
        />
        <StBadge type="users-solid" :label="card.membersNumber" size="s" />
      </div>
    </div>
    <div v-show="isCompact" class="tournament-menu-item compact">
      <PrizeAmount
        :amount="compactFormat(card.totalPrizePoolAmount)"
        :currency-icon="currencyIcon"
        :is-rise-up="isRiseUp"
        size="xs"
      />
      <StBadge
        type="pedestal-solid"
        :label="format(card.prizePlaceNumber)"
        size="xxs"
        icon-size="10"
      />
      <StBadge
        type="users-solid"
        :label="format(card.membersNumber)"
        size="xxs"
        icon-size="10"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Tournament } from '../../types'
import PrizeAmount from '../PrizeAmount/PrizeAmount.vue'

const { t } = useI18n()
const { card } = defineProps<{
  card: Tournament
}>()

const { currencyIcon, isRiseUp } = useTournament(computed(() => card))
const { countdown } = useCountdown(computed(() => card.validDateTo))
const isCompact = inject<Ref<boolean>>('isCompact', ref(false))
const { format } = useNumberFormatter({ notation: 'compact' })
const { format: compactFormat } = useCryptoFormatter({
  notation: 'compact',
  maximumFractionDigits: 0,
  locale: 'en',
})
</script>

<style scoped>
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.tournament-menu-wrapper {
  position: relative;

  overflow: hidden;

  padding: 1px; /* stylelint-disable-line  */

  background-color: transparent;
  border-radius: var(--border-radius-150);
}

.tournament-menu-animation {
  position: absolute;
  top: -50px;
  left: -50px;

  width: 350px;
  height: 200px;

  background-image: linear-gradient(
    20deg,
    rgb(255 106 20 / 0%) 30%,
    rgb(255 106 20 / 80%),
    rgb(255 106 20 / 0%) 70%
  );

  animation: spin 8s linear infinite;
}

.tournament-menu-item {
  position: relative;

  display: flex;
  flex-direction: column;
  gap: var(--spacing-125);

  padding: var(--spacing-100);

  background: var(--background-primary) url('./prize-background.svg');
  background-size: cover;
  border-radius: var(--border-radius-150, 12px);

  .tournament-info {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-height: 32px;
  }

  .title-wrapper {
    display: flex;
    flex-direction: column;
    min-width: 120px;
  }

  .subtitle {
    font: var(--desktop-text-xxs-medium);
    color: var(--text-tertiary);
  }

  .title {
    overflow: hidden;
    font: var(--desktop-text-sm-semibold);
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .tournament-badges {
    display: inline-flex;
    gap: var(--spacing-050);
  }
}

.compact {
  display: flex;
  flex-direction: column;
  gap: 3px; /* stylelint-disable-line */

  padding: var(--spacing-075);
  padding-top: var(--spacing-125);

  & > span {
    width: 100%;
  }
}
</style>
