<template>
  <section
    class="coupon"
    data-t="coupon"
    :class="{ open: isOpen, loading: isLoading, ready: isReady }"
  >
    <CouponHeader
      v-model:is-open="isOpen"
      v-model:tab="tab"
      class="coupon-header"
    />
    <div ref="el" class="content" :class="{ open: isOpen }">
      <KeepAlive>
        <BetMakingForm v-if="tab === 'coupon'" />
        <ActiveBets v-else />
      </KeepAlive>
    </div>
  </section>
</template>

<script setup lang="ts">
import CouponHeader from './CouponHeader.vue'
import BetMakingForm from '../BetMakingForm/BetMakingForm.vue'
import ActiveBets from '../ActiveBets/ActiveBets.vue'
import { useCouponStore } from '../../stores/useCouponStore'

const { outcomeCount, isLoading } = storeToRefs(useCouponStore())
const isOpen = ref(false)
const tab = ref<'coupon' | 'bets'>('coupon')

const el = ref<HTMLDivElement>()
const { height } = useElementSize(el)
const MIN_HEIGHT = 242
const couponTranslateY = computed(() => `${height.value || MIN_HEIGHT}px`)

watch(outcomeCount, (count) => {
  if (count > 0) {
    isOpen.value = true
    tab.value = 'coupon'
  }
})
const isReady = ref(false)
onNuxtReady(() => {
  isReady.value = true
})
</script>

<style scoped>
.content {
  height: 100%;
  min-height: 200px;
  background-color: var(--background-base);
  border-inline: var(--spacing-050) solid var(--background-secondary);
}

.coupon-body {
  flex-shrink: 1;
  min-height: 0;
}

.coupon-header {
  flex-shrink: 0;
}

.coupon {
  position: relative;
  transform-origin: top;
  transform: translateY(v-bind(couponTranslateY));
  /* stylelint-disable-line */

  overflow: hidden;
  display: flex;
  flex-direction: column;

  width: 340px;
  max-height: 680px;

  border-radius: var(--border-radius-150) var(--border-radius-150) 0 0;
  box-shadow: var(--shadows-dropdown);

  &.loading::after {
    content: '';
    position: absolute;
    inset: 0;
  }

  &.open {
    transform: translateY(0);
  }

  &.ready {
    transition: transform 0.26s ease-in-out;
  }
}
</style>
